<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col sm="12" class="text-center mb-4">
          <h1>{{ $t('signin.passwordResetTimedOut')}}</h1>
          <p>{{ $t('signin.passwordResetTimedOutDesc') }}</p>
          <p>
            <i18n path="signin.returnToForgetPasswordPage" tag="p">

              <a :href="'/' + $root.lang.shortName + '/forgot-password'">
                {{ $t('signin.forgotPasswordPage') }}
              </a>
            </i18n>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name : "ResetPasswordTimedOut"
}
</script>

<style></style>
